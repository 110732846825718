import React from 'react';
import { Upload, Avatar, message } from 'antd';
import { UserOutlined, EditOutlined } from '@ant-design/icons';
import imageCompression from 'browser-image-compression';

const AvatarUpload = ({ imageUrl, onChange }) => {
    const compressImage = async (file) => {
        const options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 1920,
            useWebWorker: true
        };

        try {
            const compressedFile = await imageCompression(file, options);
            return compressedFile;
        } catch (error) {
            console.error('图片压缩失败:', error);
            message.error('图片压缩失败');
            return null;
        }
    };

    const beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('只能上传 JPG/PNG 格式的图片！');
            return false;
        }

        const isLt10M = file.size / 1024 / 1024 < 10;
        if (!isLt10M) {
            message.error('图片大小不能超过 10MB！');
            return false;
        }

        return true;
    };

    const customUpload = async ({ file, onSuccess, onError }) => {
        if (!beforeUpload(file)) {
            return;
        }

        message.loading({ content: '头像处理中...', key: 'uploading' });

        try {
            const compressedFile = await compressImage(file);
            if (!compressedFile) {
                onError(new Error('Compression failed'));
                return;
            }

            const formData = new FormData();
            formData.append('file', compressedFile, file.name);

            const response = await fetch('/upload', {
                method: 'POST',
                body: formData
            });

            const data = await response.json();
            message.success({ content: '上传成功', key: 'uploading' });
            onSuccess(data);
        } catch (error) {
            message.error({ content: '上传失败', key: 'uploading' });
            onError(error);
        }
    };

    return (
        <div className="avatar-upload">
            <Upload
                name="file"
                showUploadList={false}
                customRequest={customUpload}
                onChange={onChange}
            >
                <div className="relative inline-block cursor-pointer">
                    <Avatar
                        src={imageUrl}
                        icon={!imageUrl && <UserOutlined />}
                        size={96}
                        className="border-4 border-white shadow-lg"
                    />
                    <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-40 opacity-0 hover:opacity-100 transition-opacity duration-200 rounded-full">
                        <EditOutlined className="text-white text-xl" />
                    </div>
                </div>
            </Upload>
        </div>
    );
};

export default AvatarUpload;