import React from 'react';
import { Upload, Button, message } from 'antd';
import { 
    PlusOutlined, 
    EyeOutlined, 
    EditOutlined, 
    CloseCircleOutlined 
} from '@ant-design/icons';
import imageCompression from 'browser-image-compression';

const ImageUploadSection = ({ 
    images = [], 
    onImageAdd, 
    onImageRemove, 
    onImageReplace, 
    onPreview, 
    maxCount = 3 
}) => {
    const compressImage = async (file) => {
        const options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 1920,
            useWebWorker: true
        };

        try {
            const compressedFile = await imageCompression(file, options);
            return compressedFile;
        } catch (error) {
            console.error('图片压缩失败:', error);
            message.error('图片压缩失败');
            return null;
        }
    };

    const beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('只能上传 JPG/PNG 格式的图片！');
            return false;
        }
        
        const isLt10M = file.size / 1024 / 1024 < 10;
        if (!isLt10M) {
            message.error('图片大小不能超过 10MB！');
            return false;
        }
        
        return true;
    };

    const handleUpload = async (file) => {
        if (!beforeUpload(file)) {
            return Upload.LIST_IGNORE;
        }

        message.loading({ content: '图片处理中...', key: 'uploading' });
        
        try {
            const compressedFile = await compressImage(file);
            if (!compressedFile) {
                return Upload.LIST_IGNORE;
            }

            // 创建新的 File 对象，保持原始文件名
            const processedFile = new File([compressedFile], file.name, {
                type: compressedFile.type,
            });

            // 更新文件的状态为处理完成
            const newFile = {
                ...file,
                originFileObj: processedFile
            };

            return newFile;
        } catch (error) {
            message.error({ content: '图片处理失败', key: 'uploading' });
            return Upload.LIST_IGNORE;
        }
    };

    const customUpload = async ({ file, onSuccess, onError }) => {
        const processedFile = await handleUpload(file);
        if (processedFile === Upload.LIST_IGNORE) {
            onError(new Error('Upload ignored'));
            return;
        }

        const formData = new FormData();
        formData.append('file', processedFile.originFileObj);

        try {
            const response = await fetch('/upload', {
                method: 'POST',
                body: formData
            });
            const data = await response.json();
            onSuccess(data);
            message.success({ content: '上传成功', key: 'uploading' });
        } catch (error) {
            message.error({ content: '上传失败', key: 'uploading' });
            onError(error);
        }
    };

    return (
        <div className="grid">
            {images.map((picUrl, index) => (
                <div key={index} className="relative aspect-square">
                    <div className="life-photo-wrapper">
                        <div className="life-photo-thumbnail" 
                            style={{
                                backgroundImage: `url(${picUrl})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                width: '100%',
                                height: '100%'
                            }}
                        />
                        <div className="life-photo-overlay">
                            <Button
                                type="text"
                                icon={<EyeOutlined />}
                                onClick={() => onPreview(picUrl, index)}
                                className="photo-action-btn"
                            />
                            <Upload
                                showUploadList={false}
                                customRequest={customUpload}
                                onChange={(info) => {
                                    if (info.file.status === 'done') {
                                        onImageReplace(index, info.file);
                                    }
                                }}
                            >
                                <Button
                                    type="text"
                                    icon={<EditOutlined />}
                                    className="photo-action-btn"
                                />
                            </Upload>
                            <Button
                                type="text"
                                icon={<CloseCircleOutlined />}
                                onClick={() => onImageRemove(index)}
                                className="photo-action-btn"
                            />
                        </div>
                    </div>
                </div>
            ))}

            {images.length < maxCount && (
                <div className="aspect-square">
                    <Upload
                        name="file"
                        showUploadList={false}
                        customRequest={customUpload}
                        onChange={onImageAdd}
                    >
                        <div className="upload-photo-button">
                            <div className="upload-photo-inner">
                                <PlusOutlined className="upload-icon" />
                                <span className="upload-text">上传照片</span>
                            </div>
                        </div>
                    </Upload>
                </div>
            )}
        </div>
    );
};

export default ImageUploadSection;