import React, { useEffect, useState } from 'react';
import { List, Card, Statistic, Row, Col, Avatar, message } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import axios from '../config/axios';
import './ActivityCustomerPage.css';

const ActivityCustomerPage = ({ activity }) => {
    const [loading, setLoading] = useState(false);
    const [participants, setParticipants] = useState([]);
    const [stats, setStats] = useState({
        total: 0,
        male: 0,
        female: 0
    });

    // 初始加载数据
    useEffect(() => {
        if (activity && activity.id) {
            fetchParticipants();
        }
    }, [activity]);

    const fetchParticipants = async () => {
        setLoading(true);
        try {
            const response = await axios.post('/api/activity/participants', {
                activityId: activity.id
            });

            if (response.data.code === 1000) {
                const participantsList = response.data.data;
                setParticipants(participantsList);
                
                // 计算统计数据
                const total = participantsList.length;
                const male = participantsList.filter(p => p.customer.sex === '男').length;
                const female = participantsList.filter(p => p.customer.sex === '女').length;
                
                setStats({
                    total,
                    male,
                    female
                });
            } else {
                message.error(response.data.message || '获取参与者列表失败');
            }
        } catch (error) {
            console.error('获取参与者列表失败:', error);
            message.error('获取参与者列表失败');
        } finally {
            setLoading(false);
        }
    };

    // 格式化职业信息显示
    const formatOccupationInfo = (customer) => {
        const parts = [];
        if (customer.education) parts.push(`学历：${customer.education}`);
        if (customer.workplace) parts.push(`工作单位：${customer.workplace}`);
        if (customer.occupation) parts.push(`职业：${customer.occupation}`);
        return parts.join(' | ');
    };

    return (
        <div className="activity-customer-page">
            {/* 活动基本信息 */}
            <Card className="activity-info-card" style={{ marginBottom: 16 }}>
                <h3>{activity.title}</h3>
                <p>活动时间：{activity.activity_time}</p>
                <p>活动地点：{activity.location}</p>
            </Card>

            {/* 统计信息 */}
            <Card className="stats-card" style={{ marginBottom: 16 }}>
                <Row gutter={16}>
                    <Col xs={24} sm={8}>
                        <Statistic 
                            title="总参与人数" 
                            value={stats.total} 
                            suffix={`/${activity.max_participants || '不限'}`}
                        />
                    </Col>
                    <Col xs={24} sm={8}>
                        <Statistic 
                            title="男生人数" 
                            value={stats.male} 
                            suffix={`/${activity.max_male_participants || '不限'}`}
                            valueStyle={{ color: '#1890ff' }}
                        />
                    </Col>
                    <Col xs={24} sm={8}>
                        <Statistic 
                            title="女生人数" 
                            value={stats.female} 
                            suffix={`/${activity.max_female_participants || '不限'}`}
                            valueStyle={{ color: '#eb2f96' }}
                        />
                    </Col>
                </Row>
            </Card>

            {/* 参与者列表 */}
            <List
                className="participants-list"
                loading={loading}
                dataSource={participants}
                renderItem={(item) => (
                    <List.Item>
                        <List.Item.Meta
                            avatar={
                                <Avatar 
                                    size="large"
                                    src={item.customer.headPic} 
                                    icon={!item.customer.headPic && <UserOutlined />}
                                />
                            }
                            title={
                                <div className="participant-title">
                                    <span className="participant-name">{item.customer.name}</span>
                                    <span className={`participant-gender ${item.customer.sex === '男' ? 'male' : 'female'}`}>
                                        {item.customer.sex}
                                    </span>
                                </div>
                            }
                            description={
                                <div className="participant-info">
                                    <div className="contact-info">电话：{item.customer.phoneNum}</div>
                                    <div className="occupation-info">
                                        {formatOccupationInfo(item.customer)}
                                    </div>
                                    {item.remarks && (
                                        <div className="remarks-info">备注：{item.remarks}</div>
                                    )}
                                </div>
                            }
                        />
                    </List.Item>
                )}
            />
        </div>
    );
};

export default ActivityCustomerPage;