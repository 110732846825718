import React, { useEffect } from 'react';
import { Form, Input, DatePicker, InputNumber, Select, Button, message } from 'antd';
import axios from '../config/axios';
import moment from 'moment';

const { TextArea } = Input;
const { Option } = Select;

const ActivityEditPage = ({ activity, onCancel, onSuccess }) => {
    const [form] = Form.useForm();
    const isEdit = !!activity;

    useEffect(() => {
        if (activity) {
            // 如果是编辑，设置表单初始值
            form.setFieldsValue({
                ...activity,
                activity_time: moment(activity.activity_time),
                registration_deadline: moment(activity.registration_deadline)
            });
        }
    }, [activity]);

    const onFinish = async (values) => {
        try {
            // 转换日期格式
            const data = {
                ...values,
                activity_time: values.activity_time.format('YYYY-MM-DD HH:mm:ss'),
                registration_deadline: values.registration_deadline.format('YYYY-MM-DD HH:mm:ss')
            };

            // 如果是编辑模式，添加id
            if (isEdit) {
                data.id = activity.id;
            }

            const response = await axios.post('/api/activity/upSet', data);
            
            if (response.data.code === 1000) {
                message.success(isEdit ? '活动更新成功' : '活动创建成功');
                onSuccess();
            } else {
                message.error(response.data.message || '操作失败');
            }
        } catch (error) {
            console.error('保存活动失败:', error);
            message.error('操作失败');
        }
    };

    return (
        <div className="activity-edit-form">
            <Form
                form={form}
                layout="vertical"
                onFinish={onFinish}
                initialValues={{
                    status: 'draft',
                    max_participants: 20,
                    max_male_participants: 10,
                    max_female_participants: 10
                }}
            >
                <Form.Item
                    name="title"
                    label="活动标题"
                    rules={[{ required: true, message: '请输入活动标题' }]}
                >
                    <Input placeholder="请输入活动标题" />
                </Form.Item>

                <Form.Item
                    name="location"
                    label="活动地点"
                    rules={[{ required: true, message: '请输入活动地点' }]}
                >
                    <Input placeholder="请输入活动地点" />
                </Form.Item>

                <Form.Item
                    name="activity_time"
                    label="活动时间"
                    rules={[{ required: true, message: '请选择活动时间' }]}
                >
                    <DatePicker showTime format="YYYY-MM-DD HH:mm:ss" />
                </Form.Item>

                <Form.Item
                    name="registration_deadline"
                    label="报名截止时间"
                    rules={[{ required: true, message: '请选择报名截止时间' }]}
                >
                    <DatePicker showTime format="YYYY-MM-DD HH:mm:ss" />
                </Form.Item>

                <Form.Item
                    name="max_participants"
                    label="最大参与人数"
                    rules={[{ required: true, message: '请输入最大参与人数' }]}
                >
                    <InputNumber min={1} />
                </Form.Item>

                <Form.Item
                    name="max_male_participants"
                    label="男性人数限制"
                >
                    <InputNumber min={0} />
                </Form.Item>

                <Form.Item
                    name="max_female_participants"
                    label="女性人数限制"
                >
                    <InputNumber min={0} />
                </Form.Item>

                <Form.Item
                    name="status"
                    label="活动状态"
                    rules={[{ required: true, message: '请选择活动状态' }]}
                >
                    <Select>
                        <Option value="draft">草稿</Option>
                        <Option value="published">已发布</Option>
                        <Option value="ongoing">进行中</Option>
                        <Option value="completed">已完成</Option>
                        <Option value="cancelled">已取消</Option>
                    </Select>
                </Form.Item>

                <Form.Item
                    name="description"
                    label="活动描述"
                >
                    <TextArea rows={4} placeholder="请输入活动描述" />
                </Form.Item>

                <Form.Item>
                    <Button type="default" onClick={onCancel} style={{ marginRight: 8 }}>
                        取消
                    </Button>
                    <Button type="primary" htmlType="submit">
                        {isEdit ? '更新' : '创建'}
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};

export default ActivityEditPage;