import moment from 'moment';

// 将后端数据解析为表单所需格式
export const parseFormData = (data) => {
    if (!data) return {};

    return {
        ...data,
        // 处理日期字段
        dateOfBirth: data.dateOfBirth ? moment(data.dateOfBirth) : null,
    };
};

// 将表单数据格式化为提交格式
export const formatFormData = (values, { headPic, lifePics }) => {
    return {
        ...values,
        // 处理日期字段
        dateOfBirth: values.dateOfBirth?.format('YYYY-MM-DD'),
        // 添加图片信息
        headPic,
        lifePics,
    };
};