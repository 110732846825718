import { message } from 'antd';

// 图片上传前的检查
export const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
        message.error('只能上传 JPG/PNG 格式的图片！');
        return false;
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        message.error('图片大小不能超过 2MB！');
        return false;
    }
    return isJpgOrPng && isLt2M;
};

// 如果需要添加其他上传相关的工具函数，也可以在这里添加