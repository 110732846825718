import React, { useState, useEffect } from 'react';
import { Form, Button, Card, message, Row, Col, Tabs, Input } from 'antd';
import { MobileOutlined, WechatOutlined } from '@ant-design/icons';
import { useNavigate, useSearchParams } from 'react-router-dom';
import axios from '../config/axios';
import './CustomerLogin.css';

const LoginPage = () => {
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const [countdown, setCountdown] = useState(0);
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    
    useEffect(() => {
        // Handle WeChat authorization callback
        const code = searchParams.get('code');
        if (code) {
            handleWeChatLogin(code);
        }
    }, [searchParams]);

    // 登录成功后的处理函数
    const handleLoginSuccess = () => {
        const activityId = searchParams.get('activityId');
        // 将 activityId 传递给编辑页面
        if (activityId) {
            navigate(`/customer_edit?activityId=${activityId}`);
        } else {
            navigate('/customer_edit');
        }
    };

    const handleWeChatLogin = async (code) => {
        setLoading(true);
        try {
            const res = await axios.post("/api/tCustomers/wechatLogin", { code });
            if (res.data.code === 10000) {
                handleLoginSuccess();
            } else {
                message.error('微信登录失败');
            }
        } catch (error) {
            message.error('微信登录失败，请稍后重试');
        }
        setLoading(false);
    };

    const initiateWeChatLogin = () => {
        const appId = 'wxc1f709d32c7e7d3f';
        const redirectUri = encodeURIComponent(window.location.origin + window.location.pathname);
        const wechatUrl = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appId}&redirect_uri=${redirectUri}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`;
        window.location.href = wechatUrl;
    };

    const handlePhoneLogin = (values) => {
        setLoading(true);
        axios.post("/api/tCustomers/phoneLogin", {
            phone: values.phone,
            code: values.verificationCode
        })
            .then(res => {
                if (res.data.code === 10000) {
                    handleLoginSuccess();
                } else {
                    message.error('登录失败,请检查验证码');
                }
                setLoading(false);
            })
            .catch(error => {
                message.error('登录失败，请稍后重试');
                setLoading(false);
            });
    };

    const sendVerificationCode = (phone) => {
        if (!/^1[3-9]\d{9}$/.test(phone)) {
            message.error('请输入正确的手机号');
            return;
        }

        axios.post("/api/tCustomers/sendCode", { phone })
            .then(res => {
                if (res.data.code === 10000) {
                    message.success('验证码已发送');
                    setCountdown(60);
                    const timer = setInterval(() => {
                        setCountdown((c) => {
                            if (c <= 1) {
                                clearInterval(timer);
                                return 0;
                            }
                            return c - 1;
                        });
                    }, 1000);
                } else {
                    message.error('验证码发送失败');
                }
            })
            .catch(error => {
                message.error('验证码发送失败');
            });
    };

    const PhoneLoginTab = () => (
        <Form
            form={form}
            name="phoneLogin"
            onFinish={handlePhoneLogin}
            className="login-form"
        >
            <Form.Item
                name="phone"
                rules={[
                    { required: true, message: '请输入手机号！' },
                    { pattern: /^1[3-9]\d{9}$/, message: '请输入正确的手机号！' }
                ]}
            >
                <Input
                    prefix={<MobileOutlined />}
                    placeholder="请输入手机号"
                    size="large"
                />
            </Form.Item>

            <Form.Item>
                <Row gutter={8}>
                    <Col span={16}>
                        <Form.Item
                            name="verificationCode"
                            noStyle
                            rules={[{ required: true, message: '请输入验证码！' }]}
                        >
                            <Input
                                placeholder="请输入验证码"
                                size="large"
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Button
                            size="large"
                            block
                            disabled={countdown > 0}
                            onClick={() => sendVerificationCode(form.getFieldValue('phone'))}
                        >
                            {countdown > 0 ? `${countdown}s` : '获取验证码'}
                        </Button>
                    </Col>
                </Row>
            </Form.Item>

            <Form.Item>
                <Button
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                    block
                    size="large"
                >
                    手机号登录
                </Button>
            </Form.Item>
        </Form>
    );

    const WeChatLoginTab = () => (
        <div style={{ textAlign: 'center', padding: '20px' }}>
            <Button
                type="primary"
                icon={<WechatOutlined />}
                size="large"
                onClick={initiateWeChatLogin}
                loading={loading}
                style={{ backgroundColor: '#07C160', borderColor: '#07C160' }}
            >
                微信登录
            </Button>
        </div>
    );

    return (
        <div className="login-container">
            <Card className="login-card">
                <h2 className="login-title">
                    {searchParams.get('activityId') ? '登录报名活动' : '请登录'}
                </h2>
                <Tabs
                    defaultActiveKey="phone"
                    centered
                    items={[
                        {
                            key: 'phone',
                            label: '手机号登录',
                            children: <PhoneLoginTab />
                        },
                        {
                            key: 'wechat',
                            label: '微信登录',
                            children: <WeChatLoginTab />
                        }
                    ]}
                />
            </Card>
        </div>
    );
};

export default LoginPage;