
import axios from 'axios'

const port = window.location.port;
var baseUrl = ""
if(port == 3000){
  baseUrl = "http://localhost:5600"
}

const instance = axios.create({
  baseURL: baseUrl,
  // withCredentials: true,  
  // headers: {
  //   'Content-Type': 'application/json',
  //   // 允许跨域
  //   'Access-Control-Allow-Origin': '*'
  // }
})

// axios.defaults.baseURL = "http://localhost:5600"

export default instance 
