import React, { useState, useEffect } from 'react';
import { Form, Button, Card, message, Divider, Typography } from 'antd';
import { useNavigate, useSearchParams } from 'react-router-dom';
import axios from '../../config/axios';
import moment from 'moment';

import AvatarUpload from '../components/AvatarUpload';
import LifePhotos from '../components/LifePhotos';
import FormFields from '../components/FormFields';
import { beforeUpload } from '../../utils/upload';
import './CustomerEditPage.css';

const { Title, Text } = Typography;

const CustomerEditPage = () => {
    const [form] = Form.useForm();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState('');
    const [lifePics, setLifePics] = useState([]);
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [activityInfo, setActivityInfo] = useState(null);

    useEffect(() => {
        fetchUserInfo();
        const activityId = searchParams.get('activityId');
        if (activityId) {
            fetchActivityInfo(activityId);
        }
    }, []);

    const fetchUserInfo = async () => {
        try {
            const response = await axios.post('/api/tCustomers/info');
            if (response.data.code === 10000) {
                const userInfo = response.data.data;
                setImageUrl(userInfo.headPic);
                setLifePics(userInfo.lifePics || []);
                form.setFieldsValue({
                    ...userInfo,
                    dateOfBirth: userInfo.dateOfBirth ? moment(userInfo.dateOfBirth) : undefined
                });
            }
        } catch (error) {
            message.error('获取用户信息失败');
        }
    };

    const fetchActivityInfo = async (activityId) => {
        try {
            const response = await axios.post('/api/activity/info', {
                activityId: Number(activityId)
            });
            if (response.data.code === 1000 && response.data.data.length > 0) {
                setActivityInfo(response.data.data[0]);
            } else {
                message.error(response.data.message || '获取活动信息失败');
            }
        } catch (error) {
            message.error('获取活动信息失败');
        }
    };

    const handleSignupActivity = async (activityId) => {
        try {
            const signupResponse = await axios.post('/api/activity/signup', {
                activityId: Number(activityId)
            });

            if (signupResponse.data.code === 1000) {
                message.success('活动报名成功！');
                navigate(`/activity-detail/${activityId}`);
            } else {
                message.error(signupResponse.data.message || '活动报名失败');
            }
        } catch (error) {
            message.error('活动报名失败');
        }
    };

    const onFinish = async (values) => {
        setLoading(true);
        try {
            const formData = {
                ...values,
                headPic: imageUrl,
                lifePics,
                dateOfBirth: values.dateOfBirth?.format('YYYY-MM-DD')
            };

            const response = await axios.post('/api/tCustomers/update', formData);
            if (response.data.code === 1000) {
                message.success('保存成功');
                
                // 检查是否需要报名活动
                const activityId = searchParams.get('activityId');
                if (activityId) {
                    await handleSignupActivity(activityId);
                }
            } else {
                message.error(response.data.message || '保存失败');
            }
        } catch (error) {
            message.error('保存失败');
        }
        setLoading(false);
    };

    const handleHeadPicUpload = (info) => {
        if (info.file.status === 'done') {
            setImageUrl(info.file.response.urls[0]);
            message.success('头像上传成功');
        }
    };

    const handleLifePicsUpload = (info) => {
        if (info.file.status === 'done') {
            const newUrl = info.file.response.urls[0];
            setLifePics([...lifePics, newUrl]);
            message.success(`照片上传成功`);
        } else if (info.file.status === 'error') {
            message.error(`照片上传失败`);
        }
    };

    const handleLifePicsRemove = (index) => {
        const newLifePics = [...lifePics];
        newLifePics.splice(index, 1);
        setLifePics(newLifePics);
        message.success('删除成功');
    };

    const handleLifePicsReplace = async (index, file) => {
        try {
            const formData = new FormData();
            formData.append('file', file);

            const response = await axios.post('/upload', formData);
            if (response.data.urls && response.data.urls[0]) {
                const newLifePics = [...lifePics];
                newLifePics[index] = response.data.urls[0];
                setLifePics(newLifePics);
                message.success('替换成功');
            } else {
                message.error('替换失败');
            }
        } catch (error) {
            message.error('替换失败: ' + error.message);
        }
    };

    const handlePreview = (url) => {
        setPreviewImage(url);
        setPreviewOpen(true);
    };

    return (
        <div className="edit-page-container">
            {activityInfo && searchParams.get('activityId') && (
                <Card className="activity-info-card" style={{ marginBottom: 16 }}>
                    <Title level={4}>{activityInfo.title}</Title>
                    <div className="activity-info-content">
                        <Text type="secondary">
                            活动时间：{new Date(activityInfo.activity_time).toLocaleString()}
                        </Text>
                        <br />
                        <Text type="secondary">
                            活动地点：{activityInfo.location}
                        </Text>
                        {activityInfo.description && (
                            <>
                                <br />
                                <Text type="secondary">
                                    活动描述：{activityInfo.description}
                                </Text>
                            </>
                        )}
                    </div>
                </Card>
            )}

            <Card 
                title={searchParams.get('activityId') ? "完善资料并报名活动" : "个人资料编辑"} 
                className="edit-card"
            >
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}
                    className="edit-form"
                >
                    <AvatarUpload 
                        imageUrl={imageUrl}
                        onChange={handleHeadPicUpload}
                    />
                    
                    <FormFields />
                    
                    <Form.Item label="生活照">
                        <LifePhotos
                            photos={lifePics}
                            onUpload={handleLifePicsUpload}
                            onRemove={handleLifePicsRemove}
                            onReplace={handleLifePicsReplace}
                            onPreview={handlePreview}
                            beforeUpload={beforeUpload}
                        />
                    </Form.Item>

                    {previewOpen && (
                        <div
                            className="preview-modal"
                            onClick={() => setPreviewOpen(false)}
                        >
                            <div className="preview-content">
                                <img
                                    src={previewImage}
                                    alt="预览图片"
                                    onClick={e => e.stopPropagation()}
                                />
                            </div>
                        </div>
                    )}

                    <Divider />

                    <Form.Item className="form-actions">
                        <Button
                            type="primary"
                            htmlType="submit"
                            loading={loading}
                            size="large"
                            block
                        >
                            {searchParams.get('activityId') ? "参加活动" : "保存修改"}
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
        </div>
    );
};

export default CustomerEditPage;