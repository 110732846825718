import { useState } from 'react';
import { message } from 'antd';
import axios from '../../../config/axios';
export const useImageUpload = (initialData) => {
    const [headPic, setHeadPic] = useState(initialData.headPic || '');
    const [lifePics, setLifePics] = useState(initialData.lifePics || []);

    const handleHeadPicUpload = (info) => {
        if (info.file.status === 'done') {
            setHeadPic(info.file.response.urls[0]);
            message.success('头像上传成功');
        } else if (info.file.status === 'error') {
            message.error('头像上传失败');
        }
    };

    const handleLifePicsUpload = (info) => {
        if (info.file.status === 'done') {
            const newUrl = info.file.response.urls[0];
            setLifePics([...lifePics, newUrl]);
            message.success('照片上传成功');
        } else if (info.file.status === 'error') {
            message.error('照片上传失败');
        }
    };

    const handleLifePicsRemove = (index) => {
        const newLifePics = [...lifePics];
        newLifePics.splice(index, 1);
        setLifePics(newLifePics);
        message.success('删除成功');
    };

    const handleLifePicsReplace = async (index, file) => {
        try {
            const formData = new FormData();
            formData.append('file', file);
            const response = await axios.post('/upload', formData);
            if (response.data.urls && response.data.urls[0]) {
                const newLifePics = [...lifePics];
                newLifePics[index] = response.data.urls[0];
                setLifePics(newLifePics);
                message.success('替换成功');
            }
        } catch (error) {
            message.error('替换失败: ' + error.message);
        }
    };

    return {
        headPic,
        lifePics,
        handleHeadPicUpload,
        handleLifePicsUpload,
        handleLifePicsRemove,
        handleLifePicsReplace
    };
};