import React from 'react';
import { Form, Input, Select, DatePicker, Row, Col } from 'antd';

const FormSection = ({ fields, colSpan = 12 }) => {
    const renderField = (field) => {
        const commonProps = {
            size: 'large',
            placeholder: `请${field.type === 'select' ? '选择' : '输入'}${field.label}`
        };

        switch (field.type) {
            case 'input':
                return <Input {...commonProps} disabled={field.disabled} />;
            case 'select':
                return <Select {...commonProps} options={field.options} />;
            case 'date':
                return <DatePicker {...commonProps} style={{ width: '100%' }} />;
            case 'textarea':
                return <Input.TextArea {...commonProps} rows={field.rows || 4} />;
            default:
                return null;
        }
    };

    return (
        <Row gutter={12}>
            {fields.map(field => (
                <Col key={field.name} span={24} sm={field.fullWidth ? 24 : colSpan}>
                    <Form.Item
                        name={field.name}
                        label={field.label}
                        rules={field.required ? [{ required: true, message: `请${field.type === 'select' ? '选择' : '输入'}${field.label}` }] : []}
                    >
                        {renderField(field)}
                    </Form.Item>
                </Col>
            ))}
        </Row>
    );
};

export default FormSection;