import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import MainPage from './page/MainPage';
import LoginPage from './page/LoginPage';
import CustomerLogin from './page/CustomerLogin'
import reportWebVitals from './reportWebVitals';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter, Routes,Route,Link } from 'react-router-dom';
import CustomerEditPage from './page/CustomerEditPage/CustomerEditPage'
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
          <Routes>
            <Route path="/main" element={<MainPage />}></Route>
            <Route path="/admin" element={<LoginPage />}></Route>
            <Route path="/" element={<CustomerLogin />}></Route>
            <Route path="/customer_edit" element={<CustomerEditPage />}></Route>

            
          </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: report WebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
