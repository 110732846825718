import { useState } from 'react';
import axios from '../../../config/axios';

export const useProfileData = () => {
    const [loading, setLoading] = useState(false);

    const saveProfile = async (formData) => {
        try {
            setLoading(true);
            const response = await axios.post('/api/tCustomers/upSet', formData);
            if (response.data.code !== 1000 && response.data.code !== 0) {
                throw new Error(response.data.message || '保存失败');
            }
            return response.data;
        } finally {
            setLoading(false);
        }
    };

    return {
        loading,
        saveProfile
    };
};