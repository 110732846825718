import React, { useState, useEffect } from 'react';
import { Card, Form, Button, message, Modal } from 'antd';
import { formFields } from './formConfig';
import FormSection from './FormSection';
import ImageUploadSection from './ImageUploadSection';
import AvatarUpload from '../components/AvatarUpload';
import ProfileImageGenerator from '../../utils/ProfileImageGenerator';
import { formatFormData, parseFormData } from '../../utils/formUtils';
import { useProfileData } from './hooks/useProfileData';
import { useImageUpload } from './hooks/useImageUpload';
import './CustomerProfile.css';

const CustomerProfile = ({ initialData, onSaveSuccess }) => {
  const [form] = Form.useForm();
  const { loading, saveProfile } = useProfileData();
  const { 
    headPic, 
    lifePics, 
    handleHeadPicUpload,
    handleLifePicsUpload,
    handleLifePicsRemove,
    handleLifePicsReplace 
  } = useImageUpload(initialData);

  const [previewProps, setPreviewProps] = useState({
    open: false,
    image: '',
    title: ''
  });

  useEffect(() => {
    form.setFieldsValue(parseFormData(initialData));
  }, [initialData, form]);

  const handlePreview = (image, title = '') => {
    setPreviewProps({ open: true, image, title });
  };

  const exportToImage = async () => {
    try {
      const generator = new ProfileImageGenerator();
      await generator.generateImage(initialData);
      message.success('导出成功');
    } catch (error) {
      message.error('导出失败');
    }
  };

  const onFinish = async (values) => {
    try {
      const formattedData = formatFormData(values, { headPic, lifePics });
      // 把 initialData.id 加入到提交数据中
      await saveProfile({
        ...formattedData,
        id: initialData.id,  // 添加用户id
      });
      message.success('保存成功');
      onSaveSuccess?.();
    } catch (error) {
      message.error('保存失败: ' + error.message);
    }
  };

  return (
    <div className="profile-container">
      <Card>
        <AvatarUpload
          imageUrl={headPic}
          onChange={handleHeadPicUpload}
        />

        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
        >
          {formFields.map(section => (
            <React.Fragment key={section.name}>
              <h3 className="section-title">{section.title}</h3>
              <FormSection fields={section.fields} />
            </React.Fragment>
          ))}

          <ImageUploadSection
            images={lifePics}
            onImageAdd={handleLifePicsUpload}
            onImageRemove={handleLifePicsRemove}
            onImageReplace={handleLifePicsReplace}
            onPreview={handlePreview}
          />

          <div className="form-actions">
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              size="large"
            >
              保存
            </Button>
            <Button
              onClick={exportToImage}
              disabled={loading}
              size="large"
            >
              导出为图片
            </Button>
          </div>
        </Form>

        <Modal
          open={previewProps.open}
          title={previewProps.title}
          footer={null}
          onCancel={() => setPreviewProps({ open: false })}
        >
          <img
            alt={previewProps.title}
            src={previewProps.image}
            style={{ width: '100%' }}
          />
        </Modal>
      </Card>
    </div>
  );
};

export default CustomerProfile;