export const educationOptions = [
    { label: '小学', value: '小学' },
    { label: '初中', value: '初中' },
    { label: '高中', value: '高中' },
    { label: '中专', value: '中专' },
    { label: '大专', value: '大专' },
    { label: '本科', value: '本科' },
    { label: '硕士', value: '硕士' },
    { label: '博士', value: '博士' },
    { label: '博士后', value: '博士后' }
];

export const heightOptions = Array.from({ length: 51 }, (_, i) => ({
    label: `${i + 150}cm`,
    value: i + 150
}));

export const weightOptions = Array.from({ length: 61 }, (_, i) => ({
    label: `${i + 40}kg`,
    value: i + 40
}));

export const maritalOptions = [
    { label: '已婚', value: '已婚' },
    { label: '未婚', value: '未婚' },
    { label: '离异', value: '离异' }
];

export const incomeOptions = [
    { label: '10万以下', value: '10万以下' },
    { label: '10-30万', value: '10-30万' },
    { label: '30-50万', value: '30-50万' },
    { label: '50-100万', value: '50-100万' },
    { label: '100-1000万', value: '100-1000万' },
    { label: '1000万以上', value: '1000万以上' }
];

export const formFields = [
    {
        name: 'basicInfo',
        title: '基本信息',
        fields: [
            {
                name: 'phoneNum',
                label: '手机号',
                type: 'input',
                required: true,
                disabled: true,
                rules: [
                    { required: true, message: '请输入手机号' },
                    { pattern: /^1[3-9]\d{9}$/, message: '请输入正确的手机号' }
                ]
            },
            { name: 'nickname', label: '昵称', type: 'input' },
            { name: 'name', label: '姓名', type: 'input', required: true },
            {
                name: 'sex',
                label: '性别',
                type: 'select',
                required: true,
                options: [
                    { label: '男', value: '男' },
                    { label: '女', value: '女' }
                ]
            }
        ]
    },
    {
        name: 'personalInfo',
        title: '个人信息',
        fields: [
            { name: 'dateOfBirth', label: '出生日期', type: 'date' },
            { name: 'placeOfBirth', label: '籍贯', type: 'input' },
            { name: 'heightCm', label: '身高', type: 'select', options: heightOptions },
            { name: 'weightKg', label: '体重', type: 'select', options: weightOptions }
        ]
    },
    {
        name: 'familyInfo',
        title: '家庭情况',
        fields: [
            { name: 'maritalStatus', label: '婚姻状况', type: 'select', options: maritalOptions },
            {
                name: 'hasChildren',
                label: '是否有子女',
                type: 'select',
                options: [
                    { label: '有', value: true },
                    { label: '无', value: false }
                ]
            }
        ]
    },
    {
        name: 'educationWork',
        title: '教育和工作',
        fields: [
            { name: 'education', label: '教育水平', type: 'select', options: educationOptions },
            { name: 'almaMater', label: '毕业院校', type: 'input' },
            { name: 'workplace', label: '工作单位', type: 'input' },
            { name: 'occupation', label: '职务或职业', type: 'input' }
        ]
    },
    {
        name: 'assetsInfo',
        title: '资产情况',
        fields: [
            { name: 'annualIncome', label: '税后年收入', type: 'select', options: incomeOptions },
            { name: 'propertyAndVehicleStatus', label: '房产和车辆情况', type: 'input' },
            { name: 'residence', label: '居住地', type: 'input', fullWidth: true },
            {
                name: 'personalOrFamilyAssets',
                label: '个人或家庭资产',
                type: 'textarea',
                fullWidth: true,
                rows: 3,
                showCount: true,
                maxLength: 200
            }
        ]
    },
    {
        name: 'otherInfo',
        title: '其他信息',
        fields: [
            {
                name: 'familyMembersAndBackground',
                label: '家庭成员情况及背景',
                type: 'textarea',
                fullWidth: true,
                rows: 4
            },
            {
                name: 'hobbies',
                label: '兴趣爱好',
                type: 'textarea',
                fullWidth: true,
                rows: 3
            },
            {
                name: 'Instructions',
                label: '个人说明',
                type: 'textarea',
                fullWidth: true,
                rows: 4
            },
            {
                name: 'expectation',
                label: '期望',
                type: 'textarea',
                fullWidth: true,
                rows: 3,
                showCount: true,
                maxLength: 500
            }
        ]
    }
];