export const heightOptions = Array.from({ length: 51 }, (_, i) => ({
    label: `${i + 150}cm`,
    value: i + 150
}));

export const weightOptions = Array.from({ length: 61 }, (_, i) => ({
    label: `${i + 40}kg`,
    value: i + 40
}));

export const educationOptions = [
    { label: '小学', value: '小学' },
    { label: '初中', value: '初中' },
    { label: '高中', value: '高中' },
    { label: '中专', value: '中专' },
    { label: '大专', value: '大专' },
    { label: '本科', value: '本科' },
    { label: '硕士', value: '硕士' },
    { label: '博士', value: '博士' },
    { label: '博士后', value: '博士后' }
];

export const maritalOptions = [
    { label: '未婚', value: '未婚' },
    { label: '离异', value: '离异' },
    { label: '已婚', value: '已婚' }
];

export const incomeOptions = [
    { label: '10万以下', value: '10万以下' },
    { label: '10-30万', value: '10-30万' },
    { label: '30-50万', value: '30-50万' },
    { label: '50-100万', value: '50-100万' },
    { label: '100-1000万', value: '100-1000万' },
    { label: '1000万以上', value: '1000万以上' }
];