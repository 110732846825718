import React, { useEffect, useState } from 'react';
import { Avatar, Button, List, Skeleton, Card, BackTop } from 'antd';
import { SearchOutlined, UserOutlined, UpOutlined, DownOutlined } from '@ant-design/icons';
import { Input, Select, Space, Row, Col, Form } from 'antd';
import { Dropdown, message, InputNumber, Tooltip } from 'antd';
import CustomerProfile from './CustomerProfile/CustomerProfile';
import { Modal } from 'antd';
import axios from '../config/axios';
import './CustomPage.css';

const { Option } = Select;

const CustomPage = () => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [form] = Form.useForm();
    const [collapsed, setCollapsed] = useState(true);
    const pageSize = 20;

    // 检测窗口宽度
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    // 监听窗口大小变化
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const educationOptions = [
        { label: '小学', value: '小学' },
        { label: '初中', value: '初中' },
        { label: '高中', value: '高中' },
        { label: '中专', value: '中专' },
        { label: '大专', value: '大专' },
        { label: '本科', value: '本科' },
        { label: '硕士', value: '硕士' },
        { label: '博士', value: '博士' },
        { label: '博士后', value: '博士后' }
    ];

    const maritalOptions = [
        { label: '未婚', value: '未婚' },
        { label: '离异', value: '离异' },
        { label: '已婚', value: '已婚' }
    ];

    const sexOptions = [
        { label: '不限', value: 'all' },
        { label: '男', value: '男' },
        { label: '女', value: '女' }
    ];

    const handleSearch = (values) => {
        console.log('搜索条件:', values);
        fetchData(1, values);
    };

    const fetchData = (page, filters = {}) => {
        setLoading(true);
        const processedFilters = {
            skip: (page - 1) * pageSize,
            limit: pageSize
        };

        // 处理筛选条件
        const rangeFields = [
            { key: 'age', min: 'ageRangeMin', max: 'ageRangeMax' },
            { key: 'height', min: 'heightRangeMin', max: 'heightRangeMax' },
            { key: 'weight', min: 'weightRangeMin', max: 'weightRangeMax' },
            { key: 'income', min: 'incomeRangeMin', max: 'incomeRangeMax' }
        ];

        rangeFields.forEach(({ key, min, max }) => {
            if (filters[min] || filters[max]) {
                processedFilters[`${key}Range`] = JSON.stringify({
                    min: filters[min],
                    max: filters[max]
                });
            }
        });

        // 处理其他字段
        ['placeOfBirth', 'residence', 'education', 'maritalStatus', 'hobbies'].forEach(key => {
            if (filters[key]) {
                processedFilters[key] = Array.isArray(filters[key])
                    ? JSON.stringify(filters[key])
                    : filters[key];
            }
        });

        // 特殊处理性别字段
        if (filters.sex && filters.sex !== 'all') {
            processedFilters.sex = filters.sex;
        }

        const queryParams = new URLSearchParams(processedFilters).toString();

        axios.get(`/api/tCustomers/search?${queryParams}`)
            .then(res => {
                setLoading(false);
                if (res.data.code === 0) {
                    setData(res.data.data);
                    setTotal(res.data.count);
                } else {
                    message.error(res.data.message || '获取数据失败');
                }
            })
            .catch(err => {
                console.error('请求失败:', err);
                setLoading(false);
                message.error('获取数据失败');
            });
    };

    useEffect(() => {
        fetchData(1);
    }, []);

    const formatTitleText = (item) => {
        return [item.name, item.phoneNum, item.dateOfBirth]
            .filter(Boolean)
            .join('  ');
    };

    const formatDescriptionText = (item) => {
        return [item.placeOfBirth, item.education, item.almaMater]
            .filter(Boolean)
            .join(' ');
    };

    const handleProfileUpdate = () => {
        fetchData(currentPage, form.getFieldsValue());
    };

    const detailItemClick = (e, data) => {
        Modal.info({
            width: isMobile ? '95%' : '80%',
            closable: true,
            content: <CustomerProfile initialData={data} onSaveSuccess={handleProfileUpdate} />,
            okText: '知道了',
            centered: true,
            maskClosable: false,
            className: 'custom-modal',
            icon: null,
            afterOpenChange: (visible) => {
                if (visible) {
                    // 当 Modal 打开时，将滚动条位置设置为顶部
                    const modalBody = document.querySelector('.ant-modal-body');
                    if (modalBody) {
                        modalBody.scrollTop = 0;
                    }
                    window.scrollTo(0, 0); // 滚动到页面顶部
                }
            }
        });
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
        fetchData(page, form.getFieldsValue());
    };

    const renderFormItem = (label, name, component, span = 6) => (
        <Col xs={24} sm={12} md={8} lg={span}>
            <Form.Item label={label} name={name}>
                {component}
            </Form.Item>
        </Col>
    );

    const renderRangeFormItem = (label, minName, maxName, options = {}) => (
        <Col xs={24} sm={12} md={8} lg={6}>
            <Form.Item label={label}>
                <div className="range-input-container">
                    <InputNumber
                        placeholder={`最小${options.unit || '值'}`}
                        style={{ width: '40%' }}
                        min={options.min}
                        max={options.max}
                        formatter={options.formatter}
                        parser={options.parser}
                    />
                    <span className="range-separator">-</span>
                    <InputNumber
                        placeholder={`最大${options.unit || '值'}`}
                        style={{ width: '40%' }}
                        min={options.min}
                        max={options.max}
                        formatter={options.formatter}
                        parser={options.parser}
                    />
                </div>
            </Form.Item>
        </Col>
    );

    return (
        <div className="custom-page-container">
            <Card
                className="filter-card"
                title="筛选条件"
                extra={
                    <a onClick={() => setCollapsed(!collapsed)}>
                        {collapsed ? <DownOutlined /> : <UpOutlined />}
                        {collapsed ? '展开' : '收起'}
                    </a>
                }
            >
                <div className={`filter-form ${collapsed ? 'collapsed' : ''}`}>
                    <Form
                        form={form}
                        onFinish={handleSearch}
                        layout="vertical"
                    >
                        <Row gutter={[16, 8]}>
                            {renderFormItem('性别', 'sex',
                                <Select
                                    placeholder="选择性别"
                                    options={sexOptions}
                                />
                            )}

                            {renderRangeFormItem('年龄范围', 'ageRangeMin', 'ageRangeMax', {
                                min: 18,
                                max: 60
                            })}

                            {renderRangeFormItem('身高范围', 'heightRangeMin', 'heightRangeMax', {
                                min: 150,
                                max: 200,
                                unit: 'cm',
                                formatter: value => `${value}cm`,
                                parser: value => value.replace('cm', '')
                            })}

                            {renderRangeFormItem('体重范围', 'weightRangeMin', 'weightRangeMax', {
                                min: 40,
                                max: 100,
                                unit: 'kg',
                                formatter: value => `${value}kg`,
                                parser: value => value.replace('kg', '')
                            })}

                            {renderFormItem('籍贯', 'placeOfBirth',
                                <Input placeholder="输入籍贯" />
                            )}

                            {renderFormItem('居住地', 'residence',
                                <Input placeholder="输入居住地" />
                            )}

                            {renderFormItem('学历', 'education',
                                <Select
                                    mode="multiple"
                                    placeholder="选择学历"
                                    options={educationOptions}
                                />
                            )}

                            {renderRangeFormItem('收入范围', 'incomeRangeMin', 'incomeRangeMax', {
                                formatter: value => `${value}k`,
                                parser: value => value.replace('k', '')
                            })}

                            {renderFormItem('婚姻状况', 'maritalStatus',
                                <Select
                                    placeholder="选择婚姻状况"
                                    options={maritalOptions}
                                />
                            )}

                            {renderFormItem('兴趣爱好', 'hobbies',
                                <Input placeholder="输入兴趣爱好" />
                            )}

                            <Col span={24} className="form-buttons">
                                <Space
                                    direction={isMobile ? "vertical" : "horizontal"}
                                    style={{ width: isMobile ? '100%' : 'auto' }}
                                >
                                    <Button
                                        className="action-button"
                                        onClick={() => {
                                            form.resetFields();
                                            fetchData(1);
                                        }}
                                        block={isMobile}
                                    >
                                        重置
                                    </Button>
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        className="action-button"
                                        block={isMobile}
                                    >
                                        搜索
                                    </Button>
                                </Space>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </Card>

            <List
                className="custom-list"
                loading={loading}
                itemLayout={isMobile ? "vertical" : "horizontal"}
                dataSource={data}
                pagination={{
                    onChange: handlePageChange,
                    total: total,
                    current: currentPage,
                    pageSize: pageSize,
                    showSizeChanger: false,
                    showTotal: (total) => `共 ${total} 条`,
                    size: isMobile ? 'small' : 'default'
                }}
                renderItem={(item) => (
                    <List.Item
                        actions={[
                            <a key="list-loadmore-more" onClick={(e) => detailItemClick(e, item)}>
                                详细
                            </a>
                        ]}
                    >
                        <Skeleton avatar title={false} loading={loading} active>
                            <List.Item.Meta
                                avatar={
                                    <Avatar
                                        size={isMobile ? 'small' : 'default'}
                                        src={item.headPic}
                                        icon={!item.headPic && <UserOutlined />}
                                    />
                                }
                                title={
                                    <div className="list-item-title">
                                        {formatTitleText(item)}
                                    </div>
                                }
                                description={
                                    <div className="list-item-description">
                                        {formatDescriptionText(item)}
                                    </div>
                                }
                            />
                        </Skeleton>
                    </List.Item>
                )}
            />

            <BackTop />
        </div>
    );
};

export default CustomPage;