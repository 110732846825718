import React from 'react';
import { Form, Input, Select, DatePicker, Row, Col } from 'antd';
import { PhoneOutlined, SmileOutlined } from '@ant-design/icons';
import * as configs from './formConfig';

const { TextArea } = Input;

const FormFields = () => {
    return (
        <Row gutter={[16, 16]}>
            <Col span={12}>
                <Form.Item
                    name="phoneNum"
                    label="手机号"
                    rules={[
                        { required: true, message: '请输入手机号' },
                        { pattern: /^1[3-9]\d{9}$/, message: '请输入正确的手机号' }
                    ]}
                >
                    <Input disabled prefix={<PhoneOutlined />} />
                </Form.Item>
            </Col>

            <Col span={12}>
                <Form.Item
                    name="nickname"
                    label="昵称"
                >
                    <Input prefix={<SmileOutlined />} placeholder="请输入昵称" />
                </Form.Item>
            </Col>

            <Col span={12}>
                <Form.Item
                    name="sex"
                    label="性别"
                    rules={[{ required: true, message: '请选择性别' }]}
                >
                    <Select placeholder="请选择性别">
                        <Select.Option value="男">男</Select.Option>
                        <Select.Option value="女">女</Select.Option>
                    </Select>
                </Form.Item>
            </Col>

            <Col span={12}>
                <Form.Item
                    name="name"
                    label="姓名"
                    rules={[{ required: true, message: '请输入姓名' }]}
                >
                    <Input placeholder="请输入姓名" />
                </Form.Item>
            </Col>

            <Col span={12}>
                <Form.Item name="placeOfBirth" label="籍贯">
                    <Input placeholder="请输入籍贯" />
                </Form.Item>
            </Col>

            <Col span={12}>
                <Form.Item name="dateOfBirth" label="出生日期">
                    <DatePicker style={{ width: '100%' }} placeholder="请选择出生日期" />
                </Form.Item>
            </Col>

            <Col span={12}>
                <Form.Item name="heightCm" label="身高">
                    <Select placeholder="请选择身高" options={configs.heightOptions} />
                </Form.Item>
            </Col>

            <Col span={12}>
                <Form.Item name="weightKg" label="体重">
                    <Select placeholder="请选择体重" options={configs.weightOptions} />
                </Form.Item>
            </Col>

            <Col span={12}>
                <Form.Item name="maritalStatus" label="婚姻状况">
                    <Select options={configs.maritalOptions} placeholder="请选择婚姻状况" />
                </Form.Item>
            </Col>

            <Col span={12}>
                <Form.Item name="hasChildren" label="是否有子女">
                    <Select placeholder="请选择是否有子女">
                        <Select.Option value={true}>有</Select.Option>
                        <Select.Option value={false}>无</Select.Option>
                    </Select>
                </Form.Item>
            </Col>

            <Col span={12}>
                <Form.Item name="education" label="教育水平">
                    <Select options={configs.educationOptions} placeholder="请选择教育水平" />
                </Form.Item>
            </Col>

            <Col span={12}>
                <Form.Item name="almaMater" label="毕业院校">
                    <Input placeholder="请输入毕业院校" />
                </Form.Item>
            </Col>

            <Col span={12}>
                <Form.Item name="workplace" label="工作单位">
                    <Input placeholder="请输入工作单位" />
                </Form.Item>
            </Col>

            <Col span={12}>
                <Form.Item name="occupation" label="职务或职业">
                    <Input placeholder="请输入职务或职业" />
                </Form.Item>
            </Col>

            <Col span={12}>
                <Form.Item name="annualIncome" label="税后年收入">
                    <Select options={configs.incomeOptions} placeholder="请选择税后年收入" />
                </Form.Item>
            </Col>

            <Col span={12}>
                <Form.Item name="propertyAndVehicleStatus" label="房产和车辆情况">
                    <Input placeholder="请输入房产和车辆情况" />
                </Form.Item>
            </Col>

            <Col span={12}>
                <Form.Item name="residence" label="居住地">
                    <Input placeholder="请输入居住地" />
                </Form.Item>
            </Col>

            <Col span={24}>
                <Form.Item name="personalOrFamilyAssets" label="个人或家庭资产">
                    <TextArea rows={2} placeholder="请描述个人或家庭资产情况" maxLength={200} showCount />
                </Form.Item>
            </Col>

            <Col span={24}>
                <Form.Item name="familyMembersAndBackground" label="家庭成员情况及背景">
                    <TextArea rows={4} placeholder="请输入家庭成员情况及背景" />
                </Form.Item>
            </Col>

            <Col span={24}>
                <Form.Item name="hobbies" label="兴趣爱好">
                    <TextArea rows={2} placeholder="请输入兴趣爱好" />
                </Form.Item>
            </Col>

            <Col span={24}>
                <Form.Item name="Instructions" label="个人说明">
                    <TextArea rows={4} placeholder="请输入个人说明" />
                </Form.Item>
            </Col>

            <Col span={24}>
                <Form.Item name="expectation" label="期望">
                    <TextArea rows={3} placeholder="请输入您的期望" maxLength={500} showCount />
                </Form.Item>
            </Col>
        </Row>
    );
};

export default FormFields;