import React, { useEffect, useState } from 'react';
import { Button, List, Card, Modal, message, Space, QRCode } from 'antd';
import { PlusOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import axios from '../config/axios';
import ActivityEditPage from './ActivityEditPage';
import ActivityCustomerPage from './ActivityCustomerPage';
import './ActivityListPage.css';

const { confirm } = Modal;

const ActivityListPage = () => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [editModalVisible, setEditModalVisible] = useState(false);
    const [currentActivity, setCurrentActivity] = useState(null);
    const [participantsModalVisible, setParticipantsModalVisible] = useState(false);
    const [currentViewActivity, setCurrentViewActivity] = useState(null);
    const [qrCodeModalVisible, setQrCodeModalVisible] = useState(false);
    const [currentQrCodeUrl, setCurrentQrCodeUrl] = useState('');
    const [currentQrCodeTitle, setCurrentQrCodeTitle] = useState('');
    const pageSize = 20;

    // 检测窗口宽度
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    // 监听窗口大小变化
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const fetchData = (page = 1) => {
        setLoading(true);
        
        const requestData = {
            skip: (page - 1) * pageSize,
            limit: pageSize
        };
    
        axios.post('/api/activity/list', requestData)
            .then(res => {
                setLoading(false);
                if (res.data.code === 1000) {
                    setData(res.data.data.list);
                    setTotal(res.data.data.total);
                } else {
                    message.error(res.data.message || '获取数据失败');
                }
            })
            .catch(err => {
                console.error('请求失败:', err);
                setLoading(false);
                message.error('获取数据失败');
            });
    };

    useEffect(() => {
        fetchData(1);
    }, []);

    const handlePageChange = (page) => {
        setCurrentPage(page);
        fetchData(page);
    };

    const handleCreateActivity = () => {
        setCurrentActivity(null);
        setEditModalVisible(true);
    };

    const handleEditActivity = (activity) => {
        setCurrentActivity(activity);
        setEditModalVisible(true);
    };

    const handleDeleteActivity = (activity) => {
        confirm({
            title: '确认删除',
            icon: <ExclamationCircleOutlined />,
            content: `确定要删除活动"${activity.title}"吗？`,
            okText: '确认',
            cancelText: '取消',
            onOk: async () => {
                try {
                    const response = await axios.post('/api/activity/delete', {
                        idList: [activity.id]
                    });
                    if (response.data.code === 1000) {
                        message.success('删除成功');
                        fetchData(currentPage);
                    } else {
                        message.error(response.data.message || '删除失败');
                    }
                } catch (error) {
                    console.error('删除失败:', error);
                    message.error('删除失败');
                }
            }
        });
    };

    const handleViewParticipants = (activity) => {
        setCurrentViewActivity(activity);
        setParticipantsModalVisible(true);
    };

    const handleModalCancel = () => {
        setEditModalVisible(false);
        setCurrentActivity(null);
    };

    const handleModalSuccess = () => {
        setEditModalVisible(false);
        setCurrentActivity(null);
        fetchData(currentPage);
    };

    const handleParticipantsModalClose = () => {
        setParticipantsModalVisible(false);
        setCurrentViewActivity(null);
    };

    const formatActivityStatus = (status) => {
        const statusMap = {
            'draft': '草稿',
            'published': '已发布',
            'ongoing': '进行中',
            'completed': '已完成',
            'cancelled': '已取消'
        };
        return statusMap[status] || status;
    };

    const host = window.location.host; // Get the current host

    const handleShowQrCode = (activity) => {
        setCurrentQrCodeUrl(`http://${host}/?activityId=${activity.id}`);
        setCurrentQrCodeTitle(activity.title);
        setQrCodeModalVisible(true);
    };

    return (
        <div className="activity-page-container">
            <Card 
                className="activity-card"
                title="活动列表"
                extra={
                    <Button 
                        type="primary" 
                        icon={<PlusOutlined />}
                        onClick={handleCreateActivity}
                    >
                        创建活动
                    </Button>
                }
            >
                <List
                    className="activity-list"
                    loading={loading}
                    itemLayout={isMobile ? "vertical" : "horizontal"}
                    dataSource={data}
                    pagination={{
                        onChange: handlePageChange,
                        total: total,
                        current: currentPage,
                        pageSize: pageSize,
                        showSizeChanger: false,
                        showTotal: (total) => `共 ${total} 条`,
                        size: isMobile ? 'small' : 'default'
                    }}
                    renderItem={(item) => (
                        <List.Item
                            actions={[
                                <a 
                                    key="list-participants"
                                    onClick={() => handleViewParticipants(item)}
                                >
                                    参与人员
                                </a>,
                                <a 
                                    key="list-delete"
                                    onClick={() => handleDeleteActivity(item)}
                                    style={{ color: '#ff4d4f' }}
                                >
                                    删除
                                </a>,
                                <a 
                                    key="list-edit"
                                    onClick={() => handleEditActivity(item)}
                                >
                                    编辑
                                </a>,
                                <div 
                                    key="list-qrcode" 
                                    onClick={() => handleShowQrCode(item)}
                                    style={{ cursor: 'pointer' }}
                                >
                                    <QRCode
                                        value={`http://${host}/?activityId=${item.id}`}
                                        size={64}
                                    />
                                </div>
                            ]}
                        >
                            <List.Item.Meta
                                title={<div className="activity-title">{item.title}</div>}
                                description={
                                    <Space direction="vertical">
                                        <div>活动时间：{item.activity_time}</div>
                                        <div>活动地点：{item.location}</div>
                                        <div>活动状态：{formatActivityStatus(item.status)}</div>
                                    </Space>
                                }
                            />
                        </List.Item>
                    )}
                />
            </Card>

            <Modal
                title={currentActivity ? "编辑活动" : "创建活动"}
                open={editModalVisible}
                onCancel={handleModalCancel}
                footer={null}
                width={isMobile ? "95%" : "70%"}
                destroyOnClose
            >
                <ActivityEditPage
                    activity={currentActivity}
                    onCancel={handleModalCancel}
                    onSuccess={handleModalSuccess}
                />
            </Modal>

            <Modal
                title={`${currentViewActivity?.title || ''} - 参与人员列表`}
                open={participantsModalVisible}
                onCancel={handleParticipantsModalClose}
                footer={null}
                width={isMobile ? "95%" : "80%"}
                destroyOnClose
            >
                {currentViewActivity && (
                    <ActivityCustomerPage activity={currentViewActivity} />
                )}
            </Modal>

            <Modal
                title={`${currentQrCodeTitle} - 活动二维码`}
                open={qrCodeModalVisible}
                onCancel={() => setQrCodeModalVisible(false)}
                footer={null}
                width={400}
                centered
            >
                <div style={{ display: 'flex', justifyContent: 'center', padding: '20px' }}>
                    <QRCode
                        value={currentQrCodeUrl}
                        size={300}
                        style={{ margin: '0 auto' }}
                    />
                </div>
                <div style={{ textAlign: 'center', marginTop: '10px', color: '#666' }}>
                    扫描二维码参与活动
                </div>
            </Modal>
        </div>
    );
};

export default ActivityListPage;