// utils/ProfileImageGenerator.js

class ProfileImageGenerator {
  constructor(width = 520, style = 'normal') {
    // 基础配置
    this.WIDTH = width;
    this.padding = 30;
    this.contentWidth = this.WIDTH - (this.padding * 2);
    this.headSize = 150;
    this.headerHeight = 260;
    this.titleSize = 24;
    this.subtitleSize = 16;
    this.contentSize = 14;
    this.lineHeight = 28;
    this.photoSpacing = 50;
    this.decorSize = 15;
    this.decorSpacing = 40;
    this.style = style;

    // 男性配色
    this.maleColors = {
      background: '#f5f9ff',
      primary: '#4b7bec',
      secondary: '#3867d6',
      label: '#4b6584',
      value: '#2f3542',
      cardBg: '#ffffff',
      sectionBg: '#f5f9ff',
      decorative: '#45aaf2'
    };

    // 女性配色
    this.femaleColors = {
      background: '#fff5f6',
      primary: '#ff6b81',
      secondary: '#ff4757',
      label: '#ff6348',
      value: '#2f3542',
      cardBg: '#ffffff',
      sectionBg: '#fff5f5',
      decorative: '#ffa502'
    };

    // 土豪金配色
    this.luxuryColors = {
      background: '#2C2C2C',
      primary: '#D4AF37',
      secondary: '#FFD700',
      label: '#BDB76B',
      value: '#FFFFFF',
      cardBg: '#1C1C1C',
      sectionBg: '#2C2C2C',
      decorative: '#FFD700',
      gradient1: '#FFD700',
      gradient2: '#D4AF37',
      gradient3: '#996515',
      borderColor: '#FFD700',
      shadowColor: 'rgba(255, 215, 0, 0.3)'
    };
  }
  initializeColors(sex) {
    if (this.style === 'luxury') {
      this.colors = this.luxuryColors;
    } else {
      this.colors = sex === '女' ? this.femaleColors : this.maleColors;
    }
  }

  loadImage(url) {
    console.log("加载图片")
    console.log(url)
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = 'Anonymous';
      img.onload = () => resolve(img);
      img.onerror = reject;
      img.src = url;
    });
  }

  calculateAge(birthDate) {
    if (!birthDate) return null;
    const birthday = new Date(birthDate);
    const ageDifMs = Date.now() - birthday.getTime();
    const ageDate = new Date(ageDifMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  }

  formatMoney(amount) {
    if (!amount) return null;
    if (amount >= 10000) {
      return `${(amount / 10000).toFixed(1)}万/年`;
    }
    return `${amount}元/年`;
  }

  getInfoGroups(customerData) {
    // 过滤空值的辅助函数
    const isValidValue = (value) => {
      return value !== null && value !== undefined && value !== '';
    };

    const age = this.calculateAge(customerData.dateOfBirth);
    const isFemale = customerData.sex === '女';

    const luxuryIcons = {
      about: '👑',
      life: '💎',
      intro: '🏆',
      ideal: '💫'
    };

    const normalIcons = {
      about: isFemale ? '💝' : '🌟',
      life: isFemale ? '✨' : '💪',
      intro: isFemale ? '🌈' : '🎯',
      ideal: isFemale ? '🏠' : '🚀'
    };

    const icons = this.style === 'luxury' ? luxuryIcons : normalIcons;

    const groups = [
      {
        title: `${icons.about} 关于我`,
        items: [
          isValidValue(customerData.name) && { label: '昵称', value: customerData.name },
          age && { label: '年龄', value: `${age}岁` },
          customerData.heightCm && { label: '身高', value: `${customerData.heightCm}cm` },
          customerData.weightKg && { label: '体重', value: `${customerData.weightKg}kg` },
          isValidValue(customerData.placeOfBirth) && { label: '现居地', value: customerData.placeOfBirth }
        ].filter(Boolean)
      },
      {
        title: `${icons.life} 生活方式`,
        items: [
          isValidValue(customerData.maritalStatus) && { label: '情感状况', value: customerData.maritalStatus },
          isValidValue(customerData.education) && { label: '学历', value: customerData.education },
          isValidValue(customerData.occupation) && { label: '职业', value: customerData.occupation },
          isValidValue(customerData.workplace) && { label: '单位', value: customerData.workplace }
        ].filter(Boolean)
      },
      {
        title: `${icons.intro} 个人介绍`,
        items: [
          isValidValue(customerData.hobbies) && { label: '兴趣爱好', value: customerData.hobbies },
          isValidValue(customerData.Instructions) && { label: '自我介绍', value: customerData.Instructions }
        ].filter(Boolean)
      },
      {
        title: `${icons.ideal} 理想生活`,
        items: [
          isValidValue(customerData.propertyAndVehicleStatus) && {
            label: '住房情况',
            value: customerData.propertyAndVehicleStatus
          },
          customerData.annualIncome && {
            label: '年收入',
            value: this.formatMoney(customerData.annualIncome)
          }
        ].filter(Boolean)
      }
    ];

    // 过滤掉没有任何项目的组
    return groups.filter(group => group.items.length > 0);
  }

  drawRoundRect(ctx, x, y, width, height, radius) {
    ctx.beginPath();
    ctx.moveTo(x + radius, y);
    ctx.lineTo(x + width - radius, y);
    ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
    ctx.lineTo(x + width, y + height - radius);
    ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
    ctx.lineTo(x + radius, y + height);
    ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
    ctx.lineTo(x, y + radius);
    ctx.quadraticCurveTo(x, y, x + radius, y);
    ctx.closePath();
  }

  calculateAspectRatioFit(srcWidth, srcHeight, maxWidth, maxHeight) {
    const ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);
    return {
      width: srcWidth * ratio,
      height: srcHeight * ratio
    };
  }
  drawDecoration(ctx, x, y, size = 20) {
    // 土豪金风格下不绘制装饰
    if (this.style === 'luxury') {
      return;
    }

    ctx.save();
    ctx.fillStyle = this.colors.decorative;
    if (this.colors === this.maleColors) {
      // 男性星形装饰
      ctx.beginPath();
      for (let i = 0; i < 5; i++) {
        const angle = (i * 4 * Math.PI) / 5 - Math.PI / 2;
        const x1 = x + Math.cos(angle) * size;
        const y1 = y + Math.sin(angle) * size;
        if (i === 0) ctx.moveTo(x1, y1);
        else ctx.lineTo(x1, y1);
      }
      ctx.closePath();
    } else {
      // 女性爱心装饰
      ctx.beginPath();
      ctx.moveTo(x, y + size / 4);
      ctx.bezierCurveTo(
        x, y,
        x - size / 2, y,
        x - size / 2, y + size / 4
      );
      ctx.bezierCurveTo(
        x - size / 2, y + size / 2,
        x, y + size * 3 / 4,
        x, y + size
      );
      ctx.bezierCurveTo(
        x, y + size * 3 / 4,
        x + size / 2, y + size / 2,
        x + size / 2, y + size / 4
      );
      ctx.bezierCurveTo(
        x + size / 2, y,
        x, y,
        x, y + size / 4
      );
    }
    ctx.fill();
    ctx.restore();
  }

  // 计算总高度
  calculateTotalHeight(customerData, infoGroups) {
    let height = this.headerHeight;

    // 信息组的高度
    infoGroups.forEach(group => {
      if (group.items.length > 0) {
        height += 60; // 标题和上下间距
        group.items.forEach(item => {
          if (item.value.length > 25) {
            const valueWidth = this.contentWidth - 120;
            const charsPerLine = Math.floor(valueWidth / this.contentSize);
            const lines = Math.ceil(item.value.length / charsPerLine);
            height += this.lineHeight * lines;
          } else {
            height += this.lineHeight;
          }
        });
        height += 30; // 组间距
      }
    });

    // 生活照片部分高度
    if (customerData.lifePics && Array.isArray(customerData.lifePics) && customerData.lifePics.length > 0) {
      height += 60; // 标题和间距
      const maxPhotoWidth = this.contentWidth - 40;
      customerData.lifePics.forEach(() => {
        const photoHeight = maxPhotoWidth * 0.75;
        height += photoHeight + this.photoSpacing + 40;
      });
      height += 50; // 底部额外留空
    }

    return height + this.padding * 2;
  }

  async drawHeader(ctx, customerData, headPic) {
    let currentY = this.padding + 20;

    if (this.style === 'luxury') {
      const gradient = ctx.createRadialGradient(
        this.WIDTH / 2, currentY + this.headSize / 2, this.headSize / 2,
        this.WIDTH / 2, currentY + this.headSize / 2, this.headSize
      );
      gradient.addColorStop(0, this.colors.gradient2);
      gradient.addColorStop(0.7, this.colors.gradient3);
      gradient.addColorStop(1, 'rgba(153, 101, 21, 0.5)');

      ctx.save();
      ctx.beginPath();
      ctx.arc(this.WIDTH / 2, currentY + this.headSize / 2, this.headSize / 2 + 15, 0, Math.PI * 2);
      ctx.strokeStyle = this.colors.gradient1;
      ctx.lineWidth = 3;
      ctx.fillStyle = gradient;
      ctx.fill();
      ctx.stroke();

      const innerGlow = ctx.createRadialGradient(
        this.WIDTH / 2, currentY + this.headSize / 2, 0,
        this.WIDTH / 2, currentY + this.headSize / 2, this.headSize / 2
      );
      innerGlow.addColorStop(0, 'rgba(255, 215, 0, 0.4)');
      innerGlow.addColorStop(1, 'rgba(255, 215, 0, 0)');
      ctx.fillStyle = innerGlow;
      ctx.fill();
      ctx.restore();
    } else {
      const glowColor1 = customerData.sex === '女' ? '#ffd8d8' : '#d8e6ff';
      const glowColor2 = customerData.sex === '女' ? '#fff5f6' : '#f5f9ff';
      const gradient = ctx.createRadialGradient(
        this.WIDTH / 2, currentY + this.headSize / 2, this.headSize / 2,
        this.WIDTH / 2, currentY + this.headSize / 2, this.headSize
      );
      gradient.addColorStop(0, glowColor1);
      gradient.addColorStop(1, glowColor2);

      ctx.save();
      ctx.beginPath();
      ctx.arc(this.WIDTH / 2, currentY + this.headSize / 2, this.headSize / 2 + 15, 0, Math.PI * 2);
      ctx.fillStyle = gradient;
      ctx.fill();
      ctx.restore();
    }

    // 绘制头像
    if (headPic) {
      ctx.save();
      ctx.beginPath();
      ctx.arc(this.WIDTH / 2, currentY + this.headSize / 2, this.headSize / 2, 0, Math.PI * 2);
      ctx.closePath();
      ctx.clip();
      ctx.drawImage(headPic, this.WIDTH / 2 - this.headSize / 2, currentY, this.headSize, this.headSize);
      ctx.restore();
    }

    currentY += this.headSize + 40;

    // 绘制姓名和年龄
    const age = this.calculateAge(customerData.dateOfBirth);
    const displayText = age ? `${customerData.name} · ${age}岁` : customerData.name;

    if (this.style === 'luxury') {
      const gradient = ctx.createLinearGradient(
        this.WIDTH / 2 - 100, currentY,
        this.WIDTH / 2 + 100, currentY
      );
      gradient.addColorStop(0, this.colors.gradient1);
      gradient.addColorStop(0.5, this.colors.gradient2);
      gradient.addColorStop(1, this.colors.gradient1);

      ctx.fillStyle = gradient;
      ctx.shadowColor = this.colors.shadowColor;
      ctx.shadowBlur = 10;
      ctx.shadowOffsetX = 0;
      ctx.shadowOffsetY = 0;
    } else {
      ctx.fillStyle = this.colors.primary;
    }

    ctx.font = `bold ${this.titleSize + 4}px Arial`;
    ctx.textAlign = 'center';
    ctx.fillText(displayText, this.WIDTH / 2, currentY);

    // 重置阴影效果
    ctx.shadowColor = 'transparent';
    ctx.shadowBlur = 0;

    currentY += 35;

    // 绘制标签
    const tags = [
      customerData.education,
      customerData.occupation,
      customerData.placeOfBirth
    ].filter(Boolean);

    if (tags.length > 0) {
      ctx.font = `${this.subtitleSize}px Arial`;
      if (this.style === 'luxury') {
        ctx.fillStyle = this.colors.secondary;
      } else {
        ctx.fillStyle = this.colors.secondary;
      }
      ctx.fillText(tags.join(' | '), this.WIDTH / 2, currentY);
    }

    return currentY + 40;
  }
  async drawInfoGroups(ctx, infoGroups, startY) {
    let currentY = startY;

    for (const group of infoGroups) {
      if (group.items.length === 0) continue;

      // 绘制卡片背景
      ctx.save();
      if (this.style === 'luxury') {
        ctx.shadowColor = this.colors.shadowColor;
        ctx.shadowBlur = 15;
      } else {
        ctx.shadowColor = 'rgba(0, 0, 0, 0.1)';
        ctx.shadowBlur = 10;
      }
      ctx.shadowOffsetX = 0;
      ctx.shadowOffsetY = 2;

      const cardHeight = group.items.length * this.lineHeight + 60;
      this.drawRoundRect(ctx, this.padding, currentY, this.contentWidth, cardHeight, 15);
      ctx.fillStyle = this.colors.cardBg;
      ctx.fill();

      if (this.style === 'luxury') {
        ctx.strokeStyle = this.colors.borderColor;
        ctx.lineWidth = 1;
        ctx.stroke();
      }
      ctx.restore();

      // 绘制标题
      if (this.style === 'luxury') {
        const gradient = ctx.createLinearGradient(
          this.padding, currentY,
          this.padding + 100, currentY
        );
        gradient.addColorStop(0, this.colors.gradient1);
        gradient.addColorStop(1, this.colors.gradient2);
        ctx.fillStyle = gradient;
      } else {
        ctx.fillStyle = this.colors.primary;
      }

      ctx.font = `bold ${this.titleSize}px Arial`;
      ctx.textAlign = 'left';
      ctx.fillText(group.title, this.padding + 20, currentY + 35);

      currentY += 60;

      // 绘制内容
      ctx.font = `${this.contentSize}px Arial`;
      for (const item of group.items) {
        ctx.fillStyle = this.colors.label;
        ctx.fillText(`${item.label}:`, this.padding + 20, currentY);

        ctx.fillStyle = this.colors.value;
        const valueX = this.padding + 100;

        if (item.value.length > 25) {
          const words = item.value.split('');
          let line = '';
          for (let n = 0; n < words.length; n++) {
            const testLine = line + words[n];
            if (testLine.length * this.contentSize > this.contentWidth - 120) {
              ctx.fillText(line, valueX, currentY);
              line = words[n];
              currentY += this.lineHeight;
            } else {
              line = testLine;
            }
          }
          if (line) {
            ctx.fillText(line, valueX, currentY);
          }
        } else {
          ctx.fillText(item.value, valueX, currentY);
        }

        currentY += this.lineHeight;
      }

      currentY += 30;
    }

    return currentY;
  }

  async drawLifePhotos(ctx, lifePics, startY) {
    if (!lifePics || !Array.isArray(lifePics) || lifePics.length === 0) {
      return startY;
    }

    let currentY = startY + 20;

    if (this.style === 'luxury') {
      const gradient = ctx.createLinearGradient(
        this.padding, currentY,
        this.padding + 100, currentY
      );
      gradient.addColorStop(0, this.colors.gradient1);
      gradient.addColorStop(1, this.colors.gradient2);
      ctx.fillStyle = gradient;
    } else {
      ctx.fillStyle = this.colors.primary;
    }

    ctx.font = `bold ${this.titleSize}px Arial`;
    ctx.textAlign = 'left';
    ctx.fillText('✨ 生活写真', this.padding, currentY);
    currentY += 40;

    for (const picUrl of lifePics) {
      if (!picUrl) continue;

      const pic = await this.loadImage(picUrl);

      const maxWidth = this.contentWidth - 40;
      const maxHeight = maxWidth * 0.75;
      const scale = Math.min(maxWidth / pic.width, maxHeight / pic.height);
      const width = pic.width * scale;
      const height = pic.height * scale;
      const x = this.padding + (this.contentWidth - width) / 2;

      // 绘制照片背景和阴影
      ctx.save();
      if (this.style === 'luxury') {
        ctx.shadowColor = this.colors.shadowColor;
        ctx.shadowBlur = 20;
      } else {
        ctx.shadowColor = 'rgba(0, 0, 0, 0.15)';
        ctx.shadowBlur = 15;
      }
      ctx.shadowOffsetX = 0;
      ctx.shadowOffsetY = 5;
      ctx.fillStyle = this.style === 'luxury' ? this.colors.cardBg : '#ffffff';
      this.drawRoundRect(ctx, x - 10, currentY, width + 20, height + 20, 12);
      ctx.fill();

      if (this.style === 'luxury') {
        ctx.strokeStyle = this.colors.borderColor;
        ctx.lineWidth = 1;
        ctx.stroke();
      }
      ctx.restore();

      // 绘制照片
      ctx.save();
      this.drawRoundRect(ctx, x, currentY + 10, width, height, 8);
      ctx.clip();
      ctx.drawImage(pic, x, currentY + 10, width, height);
      ctx.restore();

      currentY += height + this.photoSpacing + 20;
    }

    return currentY;
  }

  async generateImage(customerData) {
    console.log(customerData)
    if (!customerData) return;

    this.initializeColors(customerData.sex);

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const infoGroups = this.getInfoGroups(customerData);
    const totalHeight = this.calculateTotalHeight(customerData, infoGroups);

    canvas.width = this.WIDTH;
    canvas.height = totalHeight;

    // 绘制背景
    if (this.style === 'luxury') {
      const bgGradient = ctx.createLinearGradient(0, 0, 0, totalHeight);
      bgGradient.addColorStop(0, '#2C2C2C');
      bgGradient.addColorStop(1, '#1C1C1C');
      ctx.fillStyle = bgGradient;
    } else {
      const bgGradient = ctx.createLinearGradient(0, 0, 0, totalHeight);
      if (customerData.sex === '女') {
        bgGradient.addColorStop(0, '#fff5f6');
        bgGradient.addColorStop(1, '#ffefef');
      } else {
        bgGradient.addColorStop(0, '#f5f9ff');
        bgGradient.addColorStop(1, '#edf2ff');
      }
      ctx.fillStyle = bgGradient;
    }
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    try {
      const headPic = customerData.headPic ? await this.loadImage(customerData.headPic) : null;
      let currentY = await this.drawHeader(ctx, customerData, headPic);

      if (infoGroups.length > 0) {
        currentY = await this.drawInfoGroups(ctx, infoGroups, currentY);
      }

      if (customerData.lifePics && customerData.lifePics.length > 0) {
        await this.drawLifePhotos(ctx, customerData.lifePics, currentY);
      }

      return new Promise((resolve, reject) => {
        canvas.toBlob((blob) => {
          const url = URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = `${customerData.name}的个人资料.png`;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          URL.revokeObjectURL(url);
          resolve(true);
        }, 'image/png');
      });

    } catch (error) {
      console.error('生成图片时出错:', error);
      throw error;
    }
  }
}

export default ProfileImageGenerator;