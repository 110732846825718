import React, { useState } from 'react';
import { Form, Input, Button, Card, message } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import axios from '../config/axios'

const LoginPage = () => {
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();


  const onFinish = (values) => {

    setLoading(true);
    console.log('用户名:', values.username);
    console.log('密码:', values.password);
    let data = { "name": values.username, "password": values.password };
    axios.post("/api/login", data)
      .then(res => {
        console.log('res=>', res);
        if(res.data.code == 10000)
        {
          navigate('/main');
        }
        else{
          message.error('登录失败,请检查用户名密码');
          setLoading(false);
        }
        // 模拟API调用
        // setTimeout(() => {
        //   setLoading(false);
        //   // window.location.href = '/main';
        //   navigate('/main');
        // }, 1000)
      })
  };

  const onFinishFailed = (errorInfo) => {
    message.error('请完善表单信息！');
    console.log('Failed:', errorInfo);
  };

  return (
    <div style={{
      height: '100vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: '#f0f2f5'
    }}>
      <Card
        style={{
          width: 400,
          boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
          borderRadius: '8px'
        }}
      >
        <div style={{ textAlign: 'center', marginBottom: '24px' }}>
          <h2>管理员登录</h2>
        </div>

        <Form
          name="loginForm"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            name="username"
            rules={[{ required: true, message: '请输入用户名！' }]}
          >
            <Input
              prefix={<UserOutlined />}
              placeholder="请输入用户名"
              size="large"
            />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[{ required: true, message: '请输入密码！' }]}
          >
            <Input.Password
              prefix={<LockOutlined />}
              placeholder="请输入密码"
              size="large"
            />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              style={{ width: '100%' }}
              size="large"
            >
              登录
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default LoginPage;