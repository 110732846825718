import { Menu, Breadcrumb } from 'antd';
import CustomPage from './CustomPage';
import ActivityListPage from './ActivityListPage';
import { useState, useEffect } from 'react';
import Icon from '@ant-design/icons';
import '../index.css';

function MainPage() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [siderCollapsed, setSiderCollapsed] = useState(isMobile);
  const [selectedKey, setSelectedKey] = useState('1');
  const [currentBreadcrumb, setCurrentBreadcrumb] = useState('用户列表');

  // 监听窗口大小变化
  useEffect(() => {
    const handleResize = () => {
      const mobile = window.innerWidth <= 768;
      setIsMobile(mobile);
      setSiderCollapsed(mobile);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // 处理菜单点击
  const handleMenuClick = ({ key }) => {
    setSelectedKey(key);
    setCurrentBreadcrumb(key === '1' ? '用户列表' : '活动列表');
  };

  // 根据选中的菜单项渲染对应的内容
  const renderContent = () => {
    switch (selectedKey) {
      case '1':
        return <CustomPage />;
      case '2':
        return <ActivityListPage />;
      default:
        return <CustomPage />;
    }
  };

  return (
    <div className="ant-layout-topaside">
      <div className="ant-layout-wrapper">
        <div className="ant-layout-breadcrumb">
          <Breadcrumb>
            <Breadcrumb.Item>首页</Breadcrumb.Item>
            <Breadcrumb.Item>{currentBreadcrumb}</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <div className="ant-layout-container">
          {/* 移动端显示菜单切换按钮 */}
          {isMobile && (
            <div className="mobile-menu-toggle" onClick={() => setSiderCollapsed(!siderCollapsed)}>
              ☰
            </div>
          )}
          
          {/* 侧边栏 */}
          <aside className={`ant-layout-sider ${siderCollapsed ? 'collapsed' : ''}`}>
            <Menu 
              mode={isMobile ? "inline" : "inline"}
              selectedKeys={[selectedKey]}
              inlineCollapsed={siderCollapsed}
              onClick={handleMenuClick}
            >
              <Menu.Item key="1">用户列表</Menu.Item>
              <Menu.Item key="2">活动列表</Menu.Item>
            </Menu>
          </aside>
          
          <div className="ant-layout-content">
            {renderContent()}
          </div>
        </div>
        <div className="ant-layout-footer">
          愿得一人心，白首不分离
        </div>
      </div>
    </div>
  );
}

export default MainPage;